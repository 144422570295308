import type { Schema } from '@biteinc/common';
import { MaitredActivityUpdateType, regexStringFromEnum } from '@biteinc/enums';

export function buildMaitredActivityListFilterSchema(
  hasOrg: boolean,
  hasSite: boolean,
  hasLocation: boolean,
): Schema.ModelTypedObjectField {
  const schema: Schema.ModelTypedObjectField = {
    type: 'object',
    fields: {
      fromTimestamp: {
        type: 'timestamp',
        displayName: 'From',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: hasSite,
      },
      toTimestamp: {
        type: 'timestamp',
        displayName: 'To',
        allowFutureDates: false,
        allowPastDates: true,
        useSiteTimezone: hasSite,
      },
      activityBy: {
        type: 'shortString',
        displayName: 'Activity By',
        tooltip: {
          text: 'Username of the user that performed the activity',
        },
      },
      ...(hasLocation && {
        modelQuery: {
          type: 'keyString',
          displayName: 'Model',
          tooltip: {
            text: 'Model Name, Bite ID, or POS ID',
          },
        },
        updateType: {
          type: 'keyString',
          displayName: 'Update Type',
          validationRegex:
            regexStringFromEnum<MaitredActivityUpdateType>(MaitredActivityUpdateType),
          ui: 'dropdown',
          isNullable: true,
        },
      }),
      descriptionQuery: {
        type: 'shortString',
        displayName: 'Description',
        tooltip: {
          text: '"tip" for tipping events, etc',
        },
      },
      ...(hasOrg &&
        !hasSite &&
        !hasLocation && {
          locationGroupId: {
            displayName: 'Include Location-level Activities',
            type: 'keyString',
            ui: 'dropdown',
            isNullable: true,
            placeholder: 'Select Location Group',
          },
        }),
    },
  };
  return schema;
}
